import React from "react"
import styles from "./subhero.module.scss"
import Lead from "../Lead/lead"
import imgMobile1x from "./assets/spaceKitchenMobile@x1.png"
import imgMobile2x from "./assets/spaceKitchenMobile@x2.png"
import imgTablet1x from "./assets/spaceKitchenTablet@x1.png"
import imgTablet2x from "./assets/spaceKitchenTablet@x2.png"
import imgDesktop1x from "./assets/spaceKitchenDesktop_x1.jpg"
import imgDesktop2x from "./assets/spaceKitchenDesktop_x2.jpg"

const Subhero = () => (
  <div className={styles.letsTellItTogether}>
    <h2>Opowiedzmy ją razem</h2>
    <Lead>Podcast może być zwykłym zapisem rozmowy, ale&nbsp;może być też wciągającym serialem fabularnym lub porywającą dokumentalną opowieścią.</Lead>
    <Lead>W naszych rękach podcasty stają się doskonałą rozrywką dla Twoich słuchaczy, atrakcyjną formą przekazywania wiedzy i skutecznym narzędziem komunikacji marketingowej.</Lead>
    <picture className={styles.letsTellItTogetherImg}>
      <source
        media="(max-width: 517px)"
        srcSet={`${imgMobile1x}, ${imgMobile2x} 2x`}/>
      <source
        media="(max-width: 768px)"
        srcSet={`${imgTablet1x}, ${imgTablet2x} 2x`}/>
      <source
        media="(min-width: 769px)"
        srcSet={`${imgDesktop1x}, ${imgDesktop2x} 2x`}/>
      <img src={imgDesktop1x} alt="" className={styles.letsTellItTogetherImg}/>
    </picture>
  </div>
)

export default Subhero
