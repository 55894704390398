import React from 'react';
import cx from 'classnames';
import styles from './quote.module.scss';


export const sizeOptions = {
  regular: styles.regular,
}

const Quote = ({ children, className, size = sizeOptions.regular }) => (
  <span className={cx(styles.paragraph, size, className)}>{children}</span>
)

export default Quote;
