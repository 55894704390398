import React from "react"
import cx from "classnames"
import designImgDesktop from "./assets/procesKreacjaDesktop@x1.png"
import designImgDesktop2x from "./assets/procesKreacjaDesktop@x2.png"
import productionImgDesktop from "./assets/procesProdukcjaDesktop@x1.png"
import productionImgDesktop2x from "./assets/procesProdukcjaDesktop@x2.png"
import distributionImgDesktop from "./assets/procesDystrybucjaDesktop@x1.png"
import distributionImgDesktop2x from "./assets/procesDystrybucjaDesktop@x2.png"
import designImgMobile from "./assets/procesKreacjaMobile@x1.png"
import designImgMobile2x from "./assets/procesKreacjaMobile@x2.png"
import distributionImgMobile from "./assets/procesDystrybucjaMobile@x1.png"
import distributionImgMobile2x from "./assets/procesDystrybucjaMobile@x2.png"
import productionImgMobile from "./assets/procesProdukcjaMobile@x1.png"
import productionImgMobile2x from "./assets/procesProdukcjaMobile@x2.png"
import { ReactComponent as DesignFan } from "./assets/wachlarzKreacjaMobile.svg"
import { ReactComponent as ProductionFan } from "./assets/wachlarzProdukcjaMobile.svg"
import ProcessStep from "./components/ProcessStep/process-step"
import Lead from "../Lead/lead"
import styles from "./process.module.scss"
import Section from "../Section/section"

const processData = {
  header: "Jak pracujemy?",
  lead: <>Uważnie wsłuchamy się w Twoją historię i przeprowadzimy Cię przez&nbsp;kolejne etapy realizacji wyjątkowego podcastu. Stworzymy dla&nbsp;Ciebie opowieść, obok której nikt nie przejdzie obojętnie.</>,
  steps: [
    {
      header: "Kreacja",
      copy: <>Research i przygotowanie scenariusza w ramach zleconego briefu lub przedstawienie autorskiej koncepcji. Wybór i&nbsp;dostosowanie formatu, casting prowadzącego lub obsady aktorskiej.</>,
      copyMaxWidth: 380,
      img: designImgDesktop,
      imgMobile: designImgMobile,
      img2x: designImgDesktop2x,
      imgMobile2x: designImgMobile2x,
      FanComponent: DesignFan,
      fanComponentClassName: styles.designFan,
      imgMobileRatio: 510 / 694,
      className: styles.design,
      copyClassName: styles.designCopy
    },
    {
      header: "Produkcja",
      copy: <>Przeprowadzenie nagrań, redakcja treści, udźwiękowienie, miks&nbsp;i&nbsp;mastering.</>,
      img: productionImgDesktop,
      imgMobile: productionImgMobile,
      img2x: productionImgDesktop2x,
      imgMobile2x: productionImgMobile2x,
      FanComponent: ProductionFan,
      fanComponentClassName: styles.productionFan,
      imgMobileRatio: 514 / 670,
      alternative: true,
      className: styles.production,
      copyClassName: styles.productionCopy,
    },
    {
      header: "Dystrybucja",
      copy: <>Przygotowanie oprawy wizualnej, udostępnienie gotowego materiału słuchaczom, pomoc w&nbsp;przygotowaniu planu publikacji&nbsp;i&nbsp;promocji.</>,
      img: distributionImgDesktop,
      imgMobile: distributionImgMobile,
      img2x: distributionImgDesktop2x,
      imgMobile2x: distributionImgMobile2x,
      imgMobileRatio: 588 / 900,
      imgMarginRight: -50,
      className: styles.distribution,
      copyClassName: styles.distributionCopy
    }
  ]
}

const Process = ({ className, name }) => (
  <Section className={cx(styles.process, className)} name={name}>
    <h2 className={styles.header}>{processData.header}</h2>
    <Lead className={styles.lead}>{processData.lead}</Lead>
    {
      processData.steps
        .map((stepData, i) => <ProcessStep {...stepData} key={i} index={i * -1}/>)
    }
  </Section>
)

export default Process
