import React, { useState } from "react"
import cx from "classnames"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import rebelSpiritsImage from "./assets/rebelspirits.png"
import arkadiusImage from "./assets/arkadius.png"
import onlyAGameImage from "./assets/OnlyAGame.png"
import storiesFromTheEasternWestImage from "./assets/Stories From The Eastern West.png"
import priImage from "./assets/PRI_America_Abroad.png"
import theFinalCourtainImage from "./assets/The Final Curtain.png"
import wyncImage from "./assets/WNCY_On_The_Media.png"
import rnzImage from "./assets/RNZ.jpg"
import europeansImage from "./assets/The_Europeans.jpeg"
import spalonaImage from "./assets/Spalona_Ziemia.jpeg"
import styles from "./portfolio.module.scss"
import Lead from "../Lead/lead"
import Section from "../Section/section"
import Paragraph from "../Paragraph/paragraph"
import Quote from "../Quote/quote"


const portfolioData = {
  header: "Nasze produkcje",
  lead: "Zrealizowaliśmy podcasty dla największych stacji radiowych i sieci podcastów na całym świecie.",
  podcasts: [
    {
      header: "Supernowa. Historia Arkadiusa",
      copy: "Zrealizowany z ogromnym rozmachem, nagrodzony Grand Prix w konkursie Podcast Roku 2022, 7-odcinkowy audioserial reporterski opowiadający historię znanego projektanta. Vogue Polska napisał:",
      quote: "„Supernowa” jest nie tylko trzymającą w napięciu historią wzlotu i upadku, ale też wielowymiarowym portretem artysty i całego pokolenia jego fanów. Z „Supernowej” wieje wielkim światem.",
      img: arkadiusImage,
      url: "https://web.audioteka.com/pl/cycle/88fd4381-f8d0-4b32-b540-c92237923081"
    },
    {
      header: "The Europeans",
      copy: "Wojciech Oleksiak - założyciel Move Me Media współtworzy The Europeans - wielokrotnie nagradzany i wyróżniany nezależny podcast o polityce i kulturze Europy. Od 2021r., kiedy Wojciech dołączył do zespołu jego twórców, podcast został nagrodzony CIVIS PRIZE, nominowany do Prix Europa, doczekał się osobnego artykułu w The Guardian, a poszczególne epizody gościły nakanałach największych podcastów - np. Rough Translation czy Outside/In.",
      img: europeansImage,
      url: "https://europeanspodcast.com/episodes/kinga"
    },
    {
      header: "Rebel Spirits",
      copy: "Od 2020 roku produkujemy anglojęzyczny podcast opowiadający o historii i najwybitniejszych przedstawicielach polskiego jazzu. Seria doczekała się emisji na falach WorldWide.FM, jednej z największych internetowych platform poświęconych muzyce improwizowanej.",
      img: rebelSpiritsImage,
      url: "https://culture.pl/en/rebelspirits"
    },
    {
      header: "Spalona Ziemia",
      copy: "W 2023 roku jako pierwsi w Polsce zrealizowaliśmy serial fabularny w najbardziej zaawansowanej technice dźwięku 3D - ambisonii. Dzięki jej zastosowaniu, słuchacze mogą doznać pełnego zanurzenia w fikcyjnym świecie i przeżywać spotykające bohatera przygody tak, jakby stali obok niego.",
      quote: "'Mega ciekawa historia, świetnie zagrane, słucha się super. W końcu audioserial bez sztywnych dialogów czytanych w studio'",
      copy2: " - pisali słuchacze.",
      img: spalonaImage,
      url: "https://youtu.be/XbTuIOlsyyk?si=5TvYZ0ElCq4tLr55"
    },
    {
      header: "Only a Game",
      copy: "Jeden z odcinków legendarnego programu sportowego „NPR’s Only a Game”. Historia olimpijskiego złota lekkoatlety Władysława Kozakiewicza i licznych kontrowersji wywołanych jego słynnym gestem. Program wyemitowano w ponad 300 stacjach radiowych w USA, od Puerto Rico po Alaskę.",
      img: onlyAGameImage,
      url: "https://www.wbur.org/onlyagame/2018/10/26/arm-kozakiewicz-soviet-pole-vault"
    },
    {
      header: "Stories From The Eastern West",
      copy: "Podcast dla anglojęzycznych słuchaczy o tożsamości i kulturze Europy Środkowo-Wschodniej, produkowany dla Instytutu Adama Mickiewicza. Zrealizowaliśmy już ponad 70 odcinków w 10 różnych krajach. Poszczególne odcinki gościły na falach stacji radiowych z całego świata oraz znalazły się w selekcji festiwali takich jak Internazionale a Ferrara czy Lucia Festival.",
      img: storiesFromTheEasternWestImage,
      url: "https://culture.pl/en/stories-from-the-eastern-west"

    },
    {
      header: "America Abroad",
      copy: "Zrealizowaliśmy odcinek podcastu dla amerykańskiej stacji PRI - reportaż o pochodzeniu i działalności słynnego proputinowskiego trolla internetowego: Konstantina Rykova. Realizacja w koprodukcji z Molly Rosen, nowojorską producentką znaną m.in. z The Mother Jones Podcast czy On The Media.",
      img: priImage,
      url: "https://www.pri.org/stories/2018-05-07/man-who-taught-kremlin-how-win-internet"
    },
    {
      header: "The Final Curtain",
      copy: "Seria podcastów na 30. rocznicę upadku Żelaznej Kurtyny. Ponad 50 wywiadów, 6 krajów Europy Wschodniej i jeden cel: ukazać osobisty wymiar wielkich przemian społecznych i politycznych. Efektem jest 12 intymnych opowieści naocznych świadków rozpadu Bloku Wschodniego.",
      img: theFinalCourtainImage,
      url: "https://culture.pl/en/the-final-curtain"
    },
    {
      header: "On The Media",
      copy: "Z legendarnym podcastem On The Media zrealizowaliśmy reportaż o współczesnej Polsce. Na zaproszenie producentów z nowojorskiej stacji WNYC zajęliśmy się zebraniem materiałów, dotarciem do wskazanych osób, zaproponowaniem rozmówców i przeprowadzeniem wywiadów. W efekcie powstał odcinek „We need to talk about Poland”.",
      img: wyncImage,
      url: "https://www.wnycstudios.org/podcasts/otm/episodes/on-the-media-poland"
    },
    {
      header: "Voices",
      copy: "W 2019 roku wyprodukowaliśmy program o historii polskich sierot, które w trakcie Drugiej Wojny Światowej znalazły schronienie w Nowej Zelandii. Materiał został wyemitowany na falach Radio New Zealand i jest dostępny jako odcinek popularnego w Nowej Zelandii podcastu „Voices”.",
      img: rnzImage,
      url: "https://www.rnz.co.nz/national/programmes/voices/audio/2018721994/the-polish-children-of-pahiatua"
    }
  ]
}

const PortfolioItem = ({ header, copy, img, isActive, index, onClick }) => {
  const handleClick = () => {
    return onClick(index)
  }

  return (
    <div
      className={cx(styles.cover, { [ styles.active ]: isActive })}
      onClick={handleClick}
      role="button"
    >
      <img src={img} className={styles.coverImg} alt={header}/>
    </div>
  )
}

const Portfolio = ({ name }) => {
  const [ activeItem, setActiveItem ] = useState(0)

  const settings = {
    className: `slider ${styles.slick}`,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          variableWidth: false
        }
      },
      {
        breakpoint: 518,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        }
      }
    ]
  }


  return (
    <Section name={name} className={styles.portfolio}>
      <h2 className={styles.header}>{portfolioData.header}</h2>
      <Lead>{portfolioData.lead}</Lead>
      <Slider {...settings}>
        {
          portfolioData.podcasts.map((props, i) => (
            <PortfolioItem
              {...props}
              index={i}
              isActive={i === activeItem}
              key={i}
              onClick={setActiveItem}
            />)
          )
        }
      </Slider>
      <div className={styles.activeItemCopy}>
        <h4 className={styles.activeItemHeader}>
          <a href={portfolioData.podcasts[ activeItem ].url} target="_blank">
            {portfolioData.podcasts[ activeItem ].header}
          </a>
        </h4>
        <Paragraph className={styles.activeItemCopyParagraph}>{portfolioData.podcasts[ activeItem ].copy} <Quote>{portfolioData.podcasts[ activeItem ].quote}</Quote> {portfolioData.podcasts[ activeItem ].copy2}</Paragraph>
      </div>
    </Section>
  )
}

export default Portfolio
