import React from "react"
import cx from "classnames"
import styles from "./team.module.scss"
import Section from "../Section/section"
import Paragraph from "../Paragraph/paragraph"
import burzynskiImg from "./assets/jan_burzynski@x1.jpg"
import burzynskiImg2x from "./assets/jan_burzynski@x2.jpg"
import oleksiakImg from "./assets/wojciech_oleksiak@x1.jpg"
import oleksiakImg2x from "./assets/wojciech_oleksiak@x2.jpg"
import probaImg from "./assets/monika_proba@x1.jpg"
import probaImg2x from "./assets/monika_proba@x2.jpg"
import stepienImg from "./assets/magda_stepien@1x.jpg"
import stepienImg2x from "./assets/magda_stepien@2x.jpg"

const teamData = [
  {
    name: "Wojciech Oleksiak",
    role: "CEO",
    bioShort: "Producent radiowy i twórca podcastów, kompozytor i sound designer. Pomysłodawca i współautor audioserialu reporterskiego 'Supernowa. Historia Arkadiusa', za który otrzymał Grand Prix ...",
    bio: <>
      <Paragraph>Producent radiowy i twórca podcastów, kompozytor i sound designer. Pomysłodawca i współautor audioserialu reporterskiego 'Supernowa. Historia Arkadiusa', za który otrzymał Grand Prix w konkursie Podcast Roku 2022.</Paragraph>
      <Paragraph>Współtworzy wielokrotnie nagradzany i wyróżniany (m.in. przez The Guardian i New York Times) podcast The Europeans.</Paragraph>
      <Paragraph>Od 2016 produkuje butikowe podcasty dla najbardziej prestiżowych nadawców na świecie,
        współpracuje z szerokim gronem producentów w Europie i USA. Ma na swoim koncie współpracę z
        radiostacjami i sieciami takimi jak NPR, WNYC, KCRW, PRI czy Gimlet Media. Od podstaw tworzył anglojęzyzczne podcasty takie jak: Stories From The Eastern West, Rebel Spirits, The Final Curtain.</Paragraph>
      <Paragraph> Regularnie uczestniczy w festiwalach i masterclassach w USA.</Paragraph>
    </>,
    img: oleksiakImg,
    img2x: oleksiakImg2x
  },
  {
    name: "Jan Burzyński",
    role: "VP",
    bioShort: "Specjalista od języka i komunikacji, scenarzysta. W ramach pracowni językoznawstwa komputerowego Uniwersytetu Warszawskiego współtworzył Obserwatorium Językowe UW, platformę...",
    bio: <>
      <Paragraph>Specjalista od języka i komunikacji, scenarzysta. W ramach pracowni językoznawstwa
        komputerowego Uniwersytetu
        Warszawskiego współtworzył Obserwatorium Językowe UW, platformę internetową monitorującą zmiany w języku
        polskim. Wiedzę o języku popularyzuje w audycjach radiowych, programach telewizyjnych i tekstach
        publicystycznych.</Paragraph>
      <Paragraph>Jako ekspert w zakresie anglojęzycznej komunikacji naukowej, współpracuje z większością
        ośrodków naukowych w
        Polsce oraz kilkoma w Europie i USA. Szkoli kadrę akademicką w zakresie academic writing,
        przekłada i redaguje
        prace naukowe i raporty z obszaru socjologii, prawa, ekonomii oraz historii.</Paragraph>
      <Paragraph>Jako dramaturg teatralny, wystawiał m.in na deskach Teatru Dramatycznego w Warszawie,
        Muzeum POLIN, Teatru
        Polskiego w Poznaniu i Teatru im. Juliusza Słowackiego w Krakowie.</Paragraph>
      <Paragraph>Od kilku lat pracuje dla biznesu. Prowadził wiele kampanii medialnych, informacyjnych i PR-owych,
        a obecnie
        odpowiada za komunikację Polskiej Rady Biznesu, organizacji prezesów i właścicieli największych firm
        w Polsce.</Paragraph>
    </>,
    img: burzynskiImg,
    img2x: burzynskiImg2x
  },
  {
    name: "Monika Proba",
    role: "Producentka",
    bioShort: "Reżyserka filmów dokumentalnych i producentka radiowa. Z Move Me Media realizowała" +
      " podcasty dla serii:\n" +
      " Stories From The Eastern West, The Final Curtain i Rebel Spirits...",
    bio: <>
      <Paragraph>Reżyserka filmów dokumentalnych i producentka radiowa. Z Move Me Media realizowała
        podcasty dla serii:
        Stories From The Eastern West, The Final Curtain i Rebel Spirits.</Paragraph>
      <Paragraph>Ukończyła kulturoznawstwo na Uniwersytecie Warszawskim i na Sorbonie. Stypendystka
        programu Darmasiswa
        w Indonezji. Absolwentka kursu DOK PRO w szkole Wajdy.</Paragraph>
      <Paragraph>
        Reżyserka filmu „Lata świetlne” nagrodzonego m.in. na festiwalach: Żubroffka, Przeźrocza, STIFF (Chorwacja), Kortfilmfestivalen (Norwegia) i Ice Docs (Islandia) oraz wyróżnionego na Krakowskim Festiwalu Filmowym. </Paragraph>
    </>,
    img: probaImg,
    img2x: probaImg2x
  },
  {
    name: "Magda Stępień",
    role: "Producentka",
    bioShort: "Producentka podcastów i project managerka. Nieprzerwanie, od 2012 roku, realizuje projekty skupione wokół dzwięku. Od pracy przy festiwalach takich jak Unsound, Ephemera czy Sanatorium Dźwięku ...",
    bio: <>
      <Paragraph>Producentka podcastów i project managerka. Nieprzerwanie, od 2012 roku, realizuje projekty skupione wokół dźwięku.</Paragraph>
      <Paragraph>Od pracy przy festiwalach takich jak Unsound, Ephemera czy Sanatorium Dźwięku po udział w produkcji podcastowych seriali takich jak: "Supernowa. Historia Arkadiusa", "Rebel Spirits" czy "Spalona Ziemia" z Move Me Media.</Paragraph>
      <Paragraph>Koordynowała też międzynarodowe projekty dla Instytutu Adama Mickiewicza oraz prowadziła cykl 'Bitwa o kulturę' w Teatrze Starym w Lublinie. Jest autorką edycji "Cold Reading" w społecznościowym radiu Kapitał.</Paragraph>
    </>,
    img: stepienImg,
    img2x: stepienImg2x
  }
]

const TeamMember = ({ bioShort, name, onClick, img, img2x, index }) => {
  const handleClick = () => {
    const { bio, name, img } = teamData[ index ]
    onClick({
      copy: bio,
      header: name,
      img,
      img2x
    })
  }

  return (
    <div className={styles.teamMember} onClick={handleClick} onKeyDown={handleClick} role="button">
      <div className={cx(styles.img, "withoutImgAntiAliasing")}>
        <picture>
          <source
            srcSet={`${img}, ${img2x} 2x`}/>
          <img
            alt=""
            src={img}
            className={cx(styles.img, "withoutImgAntiAliasing")}
          />
        </picture>
      </div>
      <h4 className={styles.name}>{name}</h4>
      <Paragraph className={styles.bio}>{bioShort}</Paragraph>
    </div>
  )
}

const Team = ({ name, onModalOpen }) => (
  <>
    <Section className={styles.team} name={name}>
      <div className={styles.teamMembersWrapper}>
        {teamData.map((data, i) => (
          <TeamMember {...data} key={i} index={i} onClick={onModalOpen}/>
        ))}
      </div>
    </Section>
  </>
)

export default Team
