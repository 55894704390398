import React from "react"
import cx from "classnames"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import styles from "./modal.module.scss"
import * as PropTypes from "prop-types"


class Modal extends React.Component {
  scrollableElementRef = React.createRef()
  scrollableElement = null

  handleScrollLocking() {
    const { isOpen } = this.props

    if (isOpen) {
      disableBodyScroll(this.scrollableElement, { reserveScrollBarGap: true })
    } else {
      enableBodyScroll(this.scrollableElement)
    }

  }

  componentDidMount() {
    this.scrollableElement = this.scrollableElementRef.current
    this.handleScrollLocking()
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.handleScrollLocking()
    }
  }

  handleClose = (e) => {
    if (e.target === e.currentTarget) {
      this.props.onClose()
    }
  }

  render() {
    const {
      img, img2x, copy, header, isOpen, onClose
    } = this.props

    return (
      <div className={cx(styles.modalWrapper, { [ styles.open ]: isOpen })} onClick={this.handleClose}>
        <aside className={styles.modal} role="dialog">
          <button onClick={onClose} className={styles.closeButton}>Wróć</button>
          <div className={styles.modalContents} ref={this.scrollableElement}>
            {
              (img && img2x) && (
                <picture>
                  <source srcSet={`${img}, ${img2x} 2x`}/>
                  <img className={cx(styles.img, "withoutImgAntiAliasing")} src={img} alt=""/>
                </picture>
              )
            }
            <h4 className={styles.header}>{header}</h4>
            <div className={styles.bodyCopy}>
              {copy}
            </div>
          </div>
        </aside>
      </div>
    )
  }
}

Modal.propTypes = {
  img: PropTypes.any,
  copy: PropTypes.any,
  header: PropTypes.any,
  isOpen: PropTypes.any,
  onClose: PropTypes.any
}


export default Modal
