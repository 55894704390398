import React from 'react';
import cx from 'classnames';
import styles from './section.module.scss';

const Section = ({children, className, name}) => (
  <section className={cx(styles.section, className)} id={name}>
    {children}
  </section>
)

export default Section;
