import React from 'react';
import cx from 'classnames';
import styles from './paragraph.module.scss';


export const sizeOptions = {
  large: styles.large,
  regular: styles.regular,
}

const Paragraph = ({ children, className, size = sizeOptions.regular }) => (
  <p className={cx(styles.paragraph, size, className)}>{children}</p>
)

export default Paragraph;
