import React from "react"
import Paragraph from "../../../Paragraph/paragraph"
import styles from "./process-step.module.scss"
import cx from "classnames"

const ProcessStep = ({
                       alternative,
                       className,
                       copy,
                       copyClassName,
                       copyMaxWidth,
                       FanComponent,
                       fanComponentClassName,
                       header,
                       img,
                       img2x,
                       imgMobile,
                       imgMobile2x,
                       imgMobileRatio,
                       imgMarginRight,
                       index,
                     }) => (
  <div
    className={cx(styles.processStep, { [ styles.alternative ]: alternative }, className)}
  >
    <div className={cx(styles.img)}
         style={{ backgroundImage: `url(${imgMobile2x})`, paddingBottom: `calc(${imgMobileRatio} * 100%)` }}
    >
      {FanComponent  && <FanComponent className={cx(styles.fanMobile, fanComponentClassName)}/> }
    </div>
    <div className={cx(styles.copy, copyClassName)} style={{ maxWidth: copyMaxWidth }}>
      <h3>{header}</h3>
      <Paragraph>{copy}</Paragraph>
    </div>
    <img
      alt=""
      className={cx(styles.imgDesktop)} src={img}
      srcSet={`${img}, ${img2x} 2x`}
      style={{ zIndex: index, marginRight: imgMarginRight }}
    />
  </div>
)

export default ProcessStep
