import React from "react"
import cx from "classnames"
import { Link, animateScroll } from "react-scroll"
import styles from "./header.module.scss"
import { ReactComponent as CloseIcon } from "./assets/close-icon.svg"
import { ReactComponent as MenuIcon } from "./assets/menu-icon.svg"
import * as PropTypes from "prop-types"

class Header extends React.Component {
  render() {
    const { onMenuToggle, isMenuOpen, className } = this.props

    return (
      <header
        className={cx(styles.header, className)}
      >
        <nav
          className={styles.nav}
          role="navigation"
        >
          <div
            className={styles.logo}
            role="button"
            onClick={() => animateScroll.scrollToTop()}
          >
            Move Me Media
          </div>
          <button className={cx(styles.menuToggle, { [ styles.menuToggleOpen ]: isMenuOpen })}
                  onClick={onMenuToggle(!isMenuOpen)}>
            {isMenuOpen && <CloseIcon/>}
            {!isMenuOpen && <MenuIcon/>}
            <span>Menu</span>
          </button>
          <ul className={cx(styles.menu, { [ styles.open ]: isMenuOpen })}>
            <li className={styles.menuItem}>
              <Link
                activeClass={styles.active}
                to="proces"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={onMenuToggle(false)}
                title="Jak pracujemy"
              >
                Jak pracujemy
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                activeClass={styles.active}
                to="realizacje"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={onMenuToggle(false)}
                title="Nasze produkcje"
              >
                Nasze produkcje
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                activeClass={styles.active}
                to="zespol"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-150}
                duration={500}
                onClick={onMenuToggle(false)}
                title="O nas"
              >
                O nas
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                activeClass={styles.active}
                to="kontakt"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={-185}
                duration={500}
                onClick={onMenuToggle(false)}
                title="Kontakt"
              >
                Kontakt
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

Header.propTypes = {
  onMenuToggle: PropTypes.any,
  isMenuOpen: PropTypes.any,
  className: PropTypes.any
}

export default Header
