import React from "react"
import styles from "./hero.module.scss"
import heroMobileImg1x from "./assets/heroMobile@x1.png"
import heroMobileImg2x from "./assets/heroMobile@x2.png"
import heroDesktopImg1x from "./assets/heroDesktop@x1.png"
import heroDesktopImg2x from "./assets/heroDesktop@x2.png"
import { ReactComponent as HeroFanRight } from "./assets/heroFanRight.svg"
import { ReactComponent as HeroFanLeft } from "./assets/spaceKitchenDesktopMask.svg"
import { ReactComponent as HeroFanMobile } from "./assets/heroeFanMobile.svg"

const Hero = () => (
  <section className={styles.hero}>
    <HeroFanLeft className={styles.fanLeft}/>
    <HeroFanMobile className={styles.fanLeftMobile}/>
    <h1 className={styles.header}>Jaka jest Twoja historia?</h1>
    <picture className={styles.img}>
      <source
        media="(max-width: 1023px)"
        srcSet={`${heroMobileImg1x}, ${heroMobileImg2x} 2x`}/>
      <source
        media="(min-width: 1024px)"
        srcSet={`${heroDesktopImg1x}, ${heroDesktopImg2x} 2x`}/>
      <img src={heroDesktopImg1x} alt=""/>
    </picture>
    <HeroFanRight className={styles.fanRight}/>
  </section>
)

export default Hero
