/**
 * App component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import cx from "classnames"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import styles from "./app.module.scss"
import Header from "../Header/header"
import "./app.scss"
import "@openfonts/playfair-display_latin-ext"
import "@openfonts/lato_latin-ext"
import Footer from "../Footer/footer"
import SEO from "../seo"
import Hero from "../Hero/hero"
import Subhero from "../Subhero/subhero"
import Process from "../Process/process"
import Portfolio from "../Portfolio/portfolio"
import Team from "../Team/team"
import Modal from "../Modal/modal"
import * as PropTypes from "prop-types"

class App extends React.Component {
  headerRef = React.createRef();
  headerElement = null;

  constructor(props) {
    super(props)

    this.state = {
      isMenuOpen: false,
      isModalOpen: false,
      modalData: {}
    }

  }

  componentDidMount() {
    this.headerElement = this.headerRef.current;
  }

  handleModalOpen = (modalData) => {
    this.setState({ isModalOpen: true, modalData })
  }

  handleModalClose = () => {
    this.setState({ isModalOpen: false })
  }

  handleMenuToggle = (state) => () => {
    if (state === true) {
      disableBodyScroll(this.headerElement)
    } else {
      enableBodyScroll(this.headerElement)
    }
    this.setState({ isMenuOpen: state })
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
  }

  render() {
    let { children } = this.props

    const { isMenuOpen, isModalOpen, modalData } = this.state

    return (
      <>
        <div>
          <Header
            className={cx({ [ styles.blur ]: isModalOpen })}
            onMenuToggle={this.handleMenuToggle} isMenuOpen={isMenuOpen}
            ref={this.headerRef}
          />
          <div
            className={cx(styles.mainContainer, { [ styles.withMenuOpen ]: isMenuOpen, [ styles.blur ]: isModalOpen })}
          >
            <main>
              {children || (
                <>
                  <SEO title="Move Me Media"/>
                  <Hero/>
                  <Subhero/>
                  <Process name="proces" className={styles.process}/>
                  <Portfolio name="realizacje"/>
                  <Team name="zespol" onModalOpen={this.handleModalOpen}/>
                </>
              )
              }
            </main>
          </div>
          <Footer id="kontakt"
                  className={cx({ [ styles.blur ]: isModalOpen || isMenuOpen })}
          />
        </div>
        <Modal
          copy={modalData.copy}
          header={modalData.header}
          img={modalData.img}
          img2x={modalData.img2x}
          isOpen={isModalOpen}
          onClose={this.handleModalClose}
          key={modalData.header}
        />
      </>
    )
  }
}

App.propTypes = { children: PropTypes.any }

export default App
