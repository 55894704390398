import React from "react"
import cx from "classnames"
import styles from "./footer.module.scss"

const Footer = ({ id, className }) => (
  <div className={cx(styles.footerWrapper, className)}>
    <footer className={styles.footer} id={id}>
      <div className={styles.copy}>
        <h2 className={styles.header}>Kontakt</h2>
        <address className="vcard">
          <a className="tel" href="tel:+48508159235">+48 508 159 235</a>
          <a className="email" href="mailto:kontakt@moveme.media">kontakt@moveme.media</a>
          <span className="adr">
            <span className="street-address">Popularna 19/1</span><br/>
            <span className="postal-code inline-block">02-473</span> <span className="locality">Warszawa</span><br/>
            <span className="country-name" aria-hidden={true}>Polska</span>
          </span>
        </address>
      </div>
    </footer>
  </div>
)

export default Footer
